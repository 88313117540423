import { template as template_ad08af69f34941fdb9697a0b1170c8ab } from "@ember/template-compiler";
const FKLabel = template_ad08af69f34941fdb9697a0b1170c8ab(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
