import { template as template_e7ad97c55dda47088785b83573a4dbf1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e7ad97c55dda47088785b83573a4dbf1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
