import { template as template_0879929a7aa84932902f3549e89a1804 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_0879929a7aa84932902f3549e89a1804(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
