import { template as template_55f2a18caa2d49b9bfe176dc5c32c2f6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_55f2a18caa2d49b9bfe176dc5c32c2f6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
